import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Dialog Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/dialog/"
    metaDescription="Uizard's dialog templates are completely customizable to reflect your branding. Colors and styles can be tailor-made and altered to your liking."
    description="
    h2:Dialog modal windows for all your app designs
    <br/>
    Drag-and-drop customizable dialog component template to create an intuitive user experience. 
    With the built-in dialog component template in Uizard, you can create rich, realistic designs that you can quickly show to your team to get feedback and iterate rapidly. <a:https://uizard.io/prototyping/>UX/UI prototyping</a> has never been so easy.
    <br/>
    h3:Completely customizable dialog templates 
    <br/>
    Uizard's dialog templates are completely customizable to reflect your branding.
    Both the individual components within a component template, as well as its colors and styles, can be tailor-made to your liking.
    <br/>
    h3:Design quickly and easily with Uizard's drag-and-drop builder
    <br/>
    Uizard's <a:https://uizard.io/templates/component-templates/>UI components</a> enable you to create popup and modal windows in no time. Drag-and-drop these pre-made templates to craft the best user experiences.
    The templates — which are part of a growing list — make designing a breeze. You can leverage various dialog components to create unique designs that include custom styling.
    "
    pages={[
      "Delete confirmation dialog",
      "Chat bot support",
      "Customer support card",
      "Subscribe to newsletter pop up",
      "Dialog card",
      "Dialog card input fields",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing dialog windows"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing dialog windows"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing dialog windows"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing dialog windows"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing dialog windows"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/component-templates/dialog/dialog_1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/component-templates/dialog/dialog_2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/component-templates/dialog/dialog_3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/component-templates/dialog/dialog_4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/component-templates/dialog/dialog_5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
